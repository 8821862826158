import React from 'react'
import { Button, Checkbox, Form, type FormProps, Input, Select, Spin } from 'antd';
import LoadingButton from '../../../components/LoadingButton';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import CustomSelectOptions from '../../../components/formComponents/CustomSelectOptions';


type EditPermissionComponentProp = {
  initialValue?: any,
  onSubmit: any,
  loading: boolean
}
const EditPermissionComponents: React.FC<EditPermissionComponentProp> = ({ initialValue, onSubmit, loading }) => {
  const [form] = Form.useForm();
  const { role } = useSelector((state: any) => state.role);
  // console.log(initialValue, 'initialValue')
  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      initialValues={initialValue}
      onFinish={onSubmit}
      autoComplete="off"
      layout="vertical"
    >
      {/* {!role.role.includes('sub-tenant') && ( */}
        {/* <Form.Item
          label="Role"
          name={'role'}
          rules={[{ required: true, message: 'Please select role!' }]}
        >
          <CustomSelectOptions value={form.getFieldValue('role')} options={[
            { value: 'tenant', label: 'Tenant' },
            { value: 'sub-tenant', label: 'Sub Tenant' },
            { value: 'support-tenant', label: 'Support Tenant' }
          ]}
            onChange={(val: any) => form.setFieldValue("role", val)}
          />
        </Form.Item> */}
      
      {/* } */}
      <Form.Item
        label="Permissions"
        name={["permissions"]}
        rules={[{ required: true, message: 'Please select at least one permission!' }]}
      >
        <Checkbox.Group>
          {role.permissions.map((permission: any) => (
            <Checkbox className='mt-3' key={permission} value={permission}>
              {_.startCase(_.toLower(permission.replace(/-/g, ' ')))}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>

      <Form.Item>
        <LoadingButton loading={loading} title={"Update"} />
      </Form.Item>
    </Form>
  )
}

export default EditPermissionComponents