import React, { useEffect } from 'react'
import { MoreVertical, ChevronLast, ChevronFirst } from "lucide-react"
import { useContext, createContext, useState } from "react"
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'


const SidebarContext = createContext(null as any)
export default function Sidebar({ children, elements }: any) {
    const [expanded, setExpanded] = useState(true)

    useEffect(() => {
        const handleResize = () => {
          setExpanded(window.innerWidth >= 800);
        };
        
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
            <nav className="h-full inline-flex flex-col  bg-grey-10 border-r shadow-sm bg-gray-100 shadow-md">
                <div className="p-4 pb-2 flex justify-between items-center">
                    <img
                        src="/apollo-logo1.png"
                        className={`overflow-hidden transition-all ${expanded ? "w-48" : "w-0"
                            }`}
                        alt="logo"
                    />
                    <button
                        onClick={() => setExpanded((curr) => !curr)}
                        className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100 text-green-700"
                    >
                        {expanded ? <ChevronFirst /> : <ChevronLast />}
                    </button>
                </div>
                <SidebarContext.Provider value={{ expanded }}>
                    <ul className="flex-1 px-3 mt-5">{children}</ul>
                </SidebarContext.Provider>

                {/* <div className="border-t flex p-3">
                    <div className="w-10 h-10 bg-green-200 rounded-full flex items-center justify-center">
                        <span className="text-green-800 font-bold">RK</span>
                    </div>
                    <div
                        className={`
              flex justify-between items-center
              overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}
          `}
                    >
                        <div className="leading-4">
                            <h4 className="font-semibold">Rafail Kasapis</h4>
                            <span className="text-xs text-gray-600">rafail@gmail.com</span>
                        </div>
                        <MoreVertical size={20} />
                    </div>
                </div> */}
            </nav>
    )
}

export function SidebarItem({ icon, text, alert, permission }: any) {
    const { expanded } = useContext(SidebarContext)
    const currentUrl = window.location.href;
    const link = text.replace(/\s+/g, '-').toLowerCase();
    const { user } = useSelector((state: any) => state.user);
    const { role } = useSelector((state: any) => state.role);

    // Log to ensure we're checking the right values
    // console.log('Role:', role);
    // console.log('Permissions:', role?.permissions);
    // console.log('Checking Permission:', permission);

    // Fallback for undefined or missing permissions
    if (!permission || !role?.permissions.includes(permission)) {
        console.log('Missing or undefined permission:', permission); // Debugging log
        return null; // Don't render if no valid permission is found
    }
    return (
        <li
            
        >
            <Link className={`
        relative flex items-center py-2 px-3 my-1
        font-medium rounded-md cursor-pointer
        transition-colors group
        ${currentUrl.includes(text.toLowerCase())
                    ? "bg-gray-300 from-gray-200 to-gray-100 text-gray-800"
                    : "hover:bg-gray-300 text-gray-600"
                }
    `} to={`/${user?.role}/${link}`}>
            {icon}
            <span
                className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"
                    }`}
            >
                {text}
            </span>
            {alert && (
                <div
                    className={`absolute right-2 w-2 h-2 rounded bg-green-400 ${expanded ? "" : "top-2"
                        }`}
                />
            )}
            {!expanded && (
                <div
                    className={`
          absolute left-full rounded-md px-2 py-1 ml-6
          bg-green-100 text-indigo-800 text-sm
          invisible opacity-20 -translate-x-3 transition-all
          group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 z-50 shadow-md
      `}
                >
                    {text}
                </div>
            )}
            </Link>

        </li>
    )
}