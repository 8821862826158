import React, { useEffect, useState } from 'react'
import { Col, Divider, Row } from 'antd'
import Chart from 'react-apexcharts';
import Notifications from '../../../components/Notifications';
import Layout from '../../../components/layouts/Layout';

const Dashboard = () => {

  return (
    <Layout title="Dashboard">
      <Row className='flex justify-between border shadow-sm rounded mt-2 py-4 px-4' gutter={[16, 16]}>
        <Col md={4}>
          <span>Today's Consumption</span>
          <div className='flex space-x-2 mt-2'>
            <h5 className="font-semibold text-xl">10.5 kWh</h5>
            <small className='bg-green-100 px-1 my-auto'>+15.8%</small>
          </div>
          <small>Compare to yesterdays consumptions</small>
        </Col>
        <Divider className='h-20 my-auto' type="vertical" />
        <Col md={4}>
          <span>Peak Hours</span>
          <div className='flex space-x-2 mt-2'>
            <h5 className="font-semibold text-xl">6.9 kWh</h5>
            <small className='bg-red-200 px-1 my-auto'>-6.2%</small>
          </div>
          <small>08:00 - 16:00</small>
        </Col>
        <Divider className='h-20 my-auto' type="vertical" />
        <Col md={4}>
          <span>Off hours</span>
          <div className='flex space-x-2 mt-2'>
            <h5 className="font-semibold text-xl">4.2 kWh</h5>
            <small className='bg-green-100 px-1 my-auto'>+5.01%</small>
          </div>
          <small>16:00 - 03:00</small>
        </Col>
        <Divider className='h-20 my-auto' type="vertical" />
        <Col md={4}>
          <span>Efficiency Rating</span>
          <div className='flex space-x-2 mt-2'>
            <h5 className="font-semibold text-xl">42% kWh</h5>
            <small className='bg-green-100 px-1 my-auto'>+2.19%</small>
          </div>
          <small>Compare to last month</small>
        </Col>
      </Row>
      
      
    </Layout>
  )
}

export default Dashboard