import { Button, Drawer, Tabs } from 'antd';
import { ArrowBigRightDash } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { client } from '../../../useRequest';
import toastr from "toastr";
import { client2 } from '../../../operationalRequest';
import EditUserComponent from './EditUserComponent';
import EditPermissionComponents from './EditPermissionComponent';
import _ from 'lodash';

type EditUserProps = {
    data: any
    mutate?: any
}
const EditUser: React.FC<EditUserProps> = ({ data, mutate }) => {
    const [open, setOpen] = useState(false);
    const [attributes, setAttributes] = useState({} as any);
    const [loading, setLoading] = useState(false)

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const editUser = async (values: any) => {

        try {
            if (loading) return;
            setLoading(true);
            const response = await client2.put(`api/users/${data?.id}`, {
                ...values,
            })

            if (response.status === 200) {
                mutate({ data: 'data' });
                setOpen(false)
                toastr.success("User Updated Successfully");
            } } catch (e: any) {
                console.log(e)
            }
            setLoading((curr: any) => false)
    };


    const editPermission = async (values: any) => {
        try {

            if (loading) return;
            setLoading((curr: any) => true)
            const response = await client2.put(`api/user/permission/${data?.id}`, {
                ...values
            })
            console.log(values)
            if (response.status === 200) {
                mutate({ data: response.data });
                setOpen(false)
                toastr.success("Permissions Updated Successfully");
            }
        } catch (e: any) {
            console.log(e)
        }
        setLoading((curr: any) => false)
    }


    return (
        <>
            <Button onClick={showDrawer} size='small' className={`bg-blue-500 size-8 rounded-full py-3 flex items-center`} ><ArrowBigRightDash /></Button>
            <Drawer size='large' title="Edit User" onClose={onClose} open={open}>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: 'User',
                            key: '1',
                            children: <EditUserComponent loading={loading} initialValue={{ ...data }} onSubmit={editUser} />,
                        },
                        {
                            label: 'Permissions',
                            key: '2',
                            children: <EditPermissionComponents loading={loading} initialValue={{ permissions: _.map(data.permissions, 'name'), role: data.role }} onSubmit={editPermission} />,
                        },

                    ]}
                />
            </Drawer>
        </>
    );
}

export default EditUser
