import { client2 } from "../../operationalRequest";
import { client } from "../../useRequest";
import { getRoleSuccess } from "../slice/roleSlice";
import { getUserPending, getUserSuccess, getUserFail } from "../slice/userSlice";


const getUserProfile = () => async (dispatch) => {
    try{
        dispatch(getUserPending);
        const response = await getUser();
        const res = await getRole();
        if(response?.status === 200){
            dispatch(getUserSuccess(response.data?.data))
            dispatch(getRoleSuccess(res.data?.data))
            
        }else{
            return dispatch(getUserFail('Fetching user failed'));
        }
    }catch(e){
        return dispatch(getUserFail(e.message));
    }
}

export default getUserProfile;

const getUser = async () => {

    try{
         const response = await client2.get(`api/auth/user`)
        return response;
    }catch(e){
        
    }
  
}

const getRole = async () => {
    try {
        const response = await client2.get('api/roles');
        return response;
    } catch (e) {
        console.error('Error fetching roles:', e); // Log the error
        throw e;
        console.log("Base URL:", process.env.REACT_APP_OPERATIONAL_URL);// Re-throw the error for handling in the calling function
    }
};
