import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { client2 } from '../../operationalRequest';
import { Badge, DatePicker, DatePickerProps } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';

const PvReport = () => {
    const [dateType, setDateType] = useState('day');
    const [date, setDate] = useState('' as any);
    const [data, setData] = useState({
        series: [
            {
                name: 'Load',  // Name for Load series
                data: [],  // This will hold the formatted load values
            },
            {
                name: 'PV Production',  // Name for PV Production series
                data: [],  // This will hold the formatted pv_production values
            },
            {
                name: 'Grid',  // Name for Grid series
                data: [],  // This will hold the formatted grid values
            },
            {
                name: 'Battery',  // Name for Battery series
                data: [],  // This will hold the formatted battery values
            },
        ],
        options: {
            chart: {
                type: 'line',  // Change chart type to line
                height: 350,
            },
            xaxis: {
                categories: [],  // This will dynamically hold the x-axis labels
                title: {
                    text: dateType === 'day' ? "Hour of Day" : 'Date',  // X-axis title
                },
            },
            yaxis: {
                title: {
                    text: 'Energy (kW)',  // Single Y-axis title for all series
                },
                labels: {
                    formatter: (value: any) =>
                        parseFloat(value).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }),  // Format Y-axis to 2 decimal places
                },
            },
            legend: {
                position: 'top',  // Position legend at the top
            },
            colors: ['#FFBF00', '#008FFB', '#32a852', '#a8324e'],  // Colors for Load, PV Production, Grid, and Battery
            dataLabels: {
                enabled: false,  // Disable data labels
            },
            tooltip: {
                y: {
                    formatter: (value: any) =>
                        parseFloat(value).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }),  // Format tooltip values to 2 decimal places
                },
            },
            stroke: {
                curve: 'smooth',  // Smooth line transition
                width: [2, 2, 2, 2],  // Line width for all series
            },
        },
    } as any);

    const fetchData = async (dateType: string) => {
        const response = await client2.get('api/dashboard/pv-data?dateType=' + dateType + '&date='+ date);
        
        let pvAxis = [] as any;  // X-axis labels
        let formattedPvProduction = [] as any;
        let formattedLoad = [] as any;
        let formattedGrid = [] as any;
        let formattedBattery = [] as any;

        if (dateType === 'day') {
            // Use hourly data for 'day'
            pvAxis = Array.from({ length: 24 }, (_, i) => `${i}:00`);
            formattedPvProduction = response?.data?.data?.pv_production ?? [];
            formattedLoad = response?.data?.data?.load ?? [];
            formattedGrid = response?.data?.data?.grid ?? [];
            formattedBattery = response?.data?.data?.battery ?? [];
        } else {
            // Use daily data for 'week' or 'month'
            pvAxis = Object.keys(response?.data?.data?.pv_production ?? []).map(date => moment(date).format('MMM DD'));
            formattedPvProduction = Object.values(response?.data?.data?.pv_production ?? []);
            formattedLoad = Object.values(response?.data?.data?.load ?? []);
            formattedGrid = Object.values(response?.data?.data?.grid ?? []);
            formattedBattery = Object.values(response?.data?.data?.battery ?? []);
        }

        setData((prevState: any) => ({
            ...prevState,
            series: [
                {
                    name: 'Load',  // Dynamic name for Load series
                    data: formattedLoad,
                },
                {
                    name: 'PV Production',  // Dynamic name for PV Production series
                    data: formattedPvProduction,
                },
                {
                    name: 'Grid',  // Dynamic name for Grid series
                    data: formattedGrid,
                },
                {
                    name: 'Battery',  // Dynamic name for Battery series
                    data: formattedBattery,
                },
            ],
            options: {
                ...prevState.options,
                xaxis: {
                    ...prevState.options.xaxis,
                    categories: pvAxis,
                    title: {
                        text: dateType === 'day' ? "Hour of Day" : 'Date',  // Update X-axis title
                    },
                },
            },
        }));
    };

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        setDate(dateString);
      };

    useEffect(() => {
        fetchData(dateType);
    }, [dateType, date]);

    return (
        <>
            <div className='flex justify-between'>
            <div className="space-x-3 flex justify-start">
                <div onClick={() => {
                    setDateType('day')
                    setDate('')
                }} className="cursor-pointer">
                    <Badge color={dateType === 'day' ? 'grey' : 'red'} count={'Day'} />
                </div>
                <div onClick={() => {
                    setDateType('week')
                    setDate('')
                }} className="cursor-pointer">
                    <Badge color={dateType === 'week' ? 'grey' : 'red'} count={'Week'} />
                </div>
                <div onClick={() => {
                    setDateType('month')
                    setDate('')
                }} className="cursor-pointer">
                    <Badge color={dateType === 'month' ? 'grey' : 'red'} count={'Month'} />
                </div>
            </div>
            <div>
                {dateType === 'day' && <DatePicker defaultValue={dayjs().subtract(1, 'day')} onChange={onChange} />}
                {dateType === 'week' && <DatePicker onChange={onChange}  picker="week" />}
                {dateType === 'month' && <DatePicker onChange={onChange}  picker="month" />}
            </div>
            </div>
            <Chart options={data.options as any} series={data.series} type="line" height={250} />
        </>
    );
};

export default PvReport;
