import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

type LoadReportProps = {
    report: any;
}

const LoadReport: React.FC<LoadReportProps> = ({ report }) => {
    const [data, setData] = useState({
        series: [],  // This will hold multiple series with individual colors
        options: {
            chart: {
                type: 'line',
                height: 350,
            },
            stroke: {
                curve: 'smooth',  // Smooth line curve
                width: [2, 2]
            },
            xaxis: {
                categories: [],  // This will dynamically hold the hour labels
                title: {
                    text: "Yesterday's Hour of Day",  // X-axis title
                },
            },
            yaxis: {
                title: {
                    text: 'Load (kW)',  // Y-axis title
                },
                labels: {
                    formatter: (value: any) =>
                        parseFloat(value).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }),  // Format Y-axis to 2 decimal places
                },
            },
            legend: {
                position: 'top',  // Position legend at the top
            },
            colors: [],  // This will dynamically hold the colors for each series
            dataLabels: {
                enabled: false,  // Disable data labels
            },
            tooltip: {
                y: {
                    formatter: (value: any) =>
                        parseFloat(value).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }),  // Format tooltip values to 2 decimal places
                }
            }
        }
    } as any);

    useEffect(() => {
        // Format the xAxisLabels for the 24 hours of the day (0 to 23)
        const loadAxis = Array.from({ length: 24 }, (_, i) => `${i}:00`);

        // Prepare individual series from the report
        const seriesData = Object.entries(report?.remoni ?? {}).map(([key, values]: [string, any]) => ({
            name: key,  // Set the name as the key (e.g., external_id)
            data: loadAxis.map((_, index) => values[index] ?? 0)  // Match hours with values, set 0 if undefined
        }));

        // Define individual colors for each series
        const colors = ['#FFBF00', '#008FFB', '#00E396', '#FF4560', '#775DD0', '#a8324e', '#a85532', '#CCCCFF', '#808000']; // Add more colors as needed

        setData((prevState: any) => ({
            ...prevState,
            series: seriesData,
            options: {
                ...prevState.options,
                xaxis: {
                    ...prevState.options.xaxis,
                    categories: loadAxis,  // Set the hour labels on X-axis
                },
                colors: colors.slice(0, seriesData.length),  // Apply colors based on the number of series
            }
        }));
    }, [report]);

    return (
        <Chart options={data.options as any} series={data.series} type="line" height={350} />
    );
};

export default LoadReport;
