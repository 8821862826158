import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';


type WeekReportProps = {
    report: any
}
const WeekReport:React.FC<WeekReportProps> = ({report}) => {
    const [data, setData] = useState({
        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                borderRadius: 100,
            },
            xaxis: {
                categories: [], // Initialize categories to prevent undefined error
            },
            yaxis: {
                labels: {
                    formatter: (value: any) => parseFloat(value).toFixed(2), // Format x-axis values to 2 decimal places
                }
            },
            legend: {
                position: 'top',
            },
            colors: ['#008FFB', '#FFBF00', '#00F396'], // Customize colors as needed
            dataLabels: {
                enabled: false,
            },
        }
    } as any);

    useEffect(() => {
        const reportData = report.barReport ?? [];

        // Find all unique dates (categories)
        const allCategories = Array.from(new Set(reportData.flatMap((item: any) => item.data.map((point: any) => point.y))));

        // Convert to month and date format and sort
        const sortedCategories = allCategories
            .map((dateString: any) => new Date(dateString)) // Convert to Date objects
            .sort((a, b) => a.getTime() - b.getTime()) // Sort in ascending order
            .map((date) => moment(date).format('MMM D')); // Format to "MMM D" (e.g., "Feb 21")

        // Initialize series data for each required category
        const seriesData = [
            {
                name: 'PV',
                data: sortedCategories.map((category) => {
                    const foundPoint = reportData.find((item: any) => item.name === 'pv_production')?.data.find((point: any) => moment(point.y).format('MMM D') === category);
                    return foundPoint ? foundPoint.x : 0;
                }),
            },
            {
                name: 'Purchased Energy',
                data: sortedCategories.map((category) => {
                    const foundPoint = reportData.find((item: any) => item.name === 'grid-negative')?.data.find((point: any) => moment(point.y).format('MMM D') === category);
                    return foundPoint ? foundPoint.x : 0;
                }),
            },
            {
                name: 'Feed In',
                data: sortedCategories.map((category) => {
                    const foundPoint = reportData.find((item: any) => item.name === 'grid-positive')?.data.find((point: any) => moment(point.y).format('MMM D') === category);
                    return foundPoint ? foundPoint.x : 0;
                }),
            },
            {
                name: 'Battery Charge',
                data: sortedCategories.map((category) => {
                    const foundPoint = reportData.find((item: any) => item.name === 'battery-positive')?.data.find((point: any) => moment(point.y).format('MMM D') === category);
                    return foundPoint ? foundPoint.x : 0;
                }),
            },
            {
                name: 'Battery Discharge',
                data: sortedCategories.map((category) => {
                    const foundPoint = reportData.find((item: any) => item.name === 'battery-negative')?.data.find((point: any) => moment(point.y).format('MMM D') === category);
                    return foundPoint ? foundPoint.x : 0;
                }),
            },
            {
                name: 'Load',
                data: sortedCategories.map((category) => {
                    const foundPoint = reportData.find((item: any) => item.name === 'load')?.data.find((point: any) => moment(point.y).format('MMM D') === category);
                    return foundPoint ? foundPoint.x : 0;
                }),
            },
        ];

        // Debugging: Log seriesData to check if all data is included correctly
        // console.log("Series Data:", seriesData);

        // Set the chart data
        setData({
            series: seriesData,
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: false, // Set to false for separate columns
                    toolbar: {
                        show: true,
                    },
                },
                xaxis: {
                    title: {
                        text: 'Dates',
                    },
                    categories: sortedCategories, // Use formatted categories for x-axis labels
                },
                yaxis: {
                    title: {
                        text: 'Power Consumption (kW)',
                    },
                    labels: {
                        formatter: (value: any) => parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' kW', // Format y-axis values to 2 decimal places
                    },
                },
                legend: {
                    position: 'top',
                },
                colors: ['#f2a915', '#64a2ff', '#63d5e3', '#0a74da', '#4fc3f7', "#ceb104"], // Add colors for each category
                dataLabels: {
                    enabled: false, // Disable data labels inside the bars
                },
            },
        });

    }, [report])
  return (
    <Chart options={data.options} series={data.series} type="bar" width="500" height={350} />

  )
}

export default WeekReport